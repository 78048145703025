import Testimonial from '../../components/Testimonial';


function TestimonialPage() {

  return (
    <>
      <Testimonial buttonFlag={false}/>
    </>
  )
}

export default TestimonialPage;
