import acupuntura from '../../assets/SpecialtiesImg/acupuntura.jpg';

function Acupuntura() {

      return (
            <>
                  <div className="wrapper row3" id="acupuntura">
                        <main className="hoc container clear">
                              <article className="group">
                                    <div className="one_half first">
                                          <h6 className="heading underline font-x2">Acupuntura</h6>
                                          <p className="btmspace-30">
                                                Acupuntura é especialidade médica, não terapia alternativa.
                                          </p>
                                          <p className="btmspace-30">
                                                Método terapêutico de neuromodulação periférica originário da China, a acupuntura foi reconhecida, no Brasil, como especialidade médica pelo Conselho Federal de Medicina, em 1995. Devido ao procedimento pouco convencional - inserção de agulhas finas na pele - às vezes a acupuntura gera desconfiança nos pacientes. No entanto, a técnica é indicada por especialistas de diferentes áreas no tratamento de diversas doenças e conta com cobertura até mesmo de planos de saúde.
                                          </p>


                                    </div>
                                    <div className="one_half">
                                          <div className="imgover" href="">
                                                <img className="borderedbox inspace-10" src={acupuntura} alt="Dra. Silvia" />
                                          </div>
                                    </div>
                              </article>

                              <div className="clear">
                                    <p className="btmspace-30">
                                          A inserção da agulha estimula as terminações nervosas livres existentes na pele e nos tecidos subjacentes, principalmente nos músculos. A "mensagem" gerada por esses estímulos chega ao sistema nervoso central, desencadeando uma série de reações mediadas por neurotransmissores, que bloqueiam a passagem do estímulo doloroso, minimizando o processo de dor. Daí os efeitos analgésico, anti-inflamatório e relaxante muscular da acupuntura. A Organização Mundial da Saúde listou uma série de doenças tratáveis pela acupuntura, desde doenças neurológicas, psiquiátricas, ortopédicas, respiratórias, reumatológicas, digestivas, entre outras.
                                    </p>
                                    <p className="btmspace-30">
                                          A acupuntura tem a capacidade de ajustar canais energéticos do corpo, denominados na acupuntura de meridianos, de acordo com equilíbrio de yin e yang. O método consegue liberar as substâncias químicas que transformam o sistema nervoso, podendo ter resultados no corpo inteiro, gerando o equilíbrio do organismo.
                                    </p>
                                    <p className="btmspace-30">
                                          A acupuntura é reconhecida como especialidade médica, sendo muito importante para a diminuição da dor em casos de fibromialgia, dores nas costas, tratamento de náuseas e vômitos em pessoas que estão realizando quimioterapias ou que já passaram por cirurgias, além da redução da tensão emocional.
                                    </p>
                                    <p className="btmspace-30">
                                          Em alguns casos, a técnica pode ser usada como único tratamento, em outros, é associada com o uso de remédios e outras terapias.
                                    </p>

                                    <ul>
                                          <li>
                                                <p>
                                                      Importante é que o profissional esteja habilitado e não só saiba como fazer a acupuntura de maneira correta e segura, mas também seja capaz de diagnosticar o problema do paciente e indicar o tratamento adequado.
                                                </p>
                                          </li>
                                    </ul>

                                    <h1><strong>Agulhamento de Patologias Miofisicas: </strong></h1>

                                    <p className="btmspace-30">
                                          Os pontos-gatilho miofasciais (PGMs) são definidos como nódulos palpáveis presentes numa faixa tensa localizada no músculo que, espontaneamente ou à dígito-pressão, produzem um padrão de dor referida reconhecida pelo paciente.
                                    </p>
                                    <p className="btmspace-30">
                                          A fisiopatologia da formação dos PGMs ainda não está bem esclarecida, existindo, portanto, várias teorias que tentam explicar tal processo. Condições lesivas como macrotraumas, microtraumas, isquemia, inflamação, sobrecarga funcional, estresse emocional, disfunções endócrinas, deficiências nutricionais e infecções crônicas são consideradas predisponentes para o aparecimento dos PGMs.
                                    </p>
                                    <p className="btmspace-30">
                                          A síndrome dolorosa miofascial (SDM) é uma das causas mais comuns de dor músculo-esquelética. É uma condição dolorosa muscular regional, caracterizada pela ocorrência de bandas musculares tensas palpáveis, nas quais se identificam áreas hipersensíveis, os pontos-gatilho Estes, quando estimulados por palpação digital, geram dor localmente, à distância ou referida. Acomete músculos, tecidos conjuntivos e fáscias, principalmente na região cervical, cintura escapular e lombar. A dor e a incapacidade gerada pela SDM podem ser bastante significativas.
                                    </p>
                                    <p className="btmspace-30">
                                          Se a causa da dor ou lombalgia for muscular, o especialista pode indicar uma infiltração em ponto-gatilho, também chamada de inativação de ponto-gatilho, para que seja possível obter o alívio da dor miofascial (Síndrome Dolorosa Miofascial).
                                    </p>
                                    <p className="btmspace-30">
                                          O “agulhamento a seco” foi descrito pela primeira vez há mais de 2.000 anos atrás no mais antigo e mais completo existente Tratado médico Chinês, o Livro do Imperador Amarelo (Huangdi Neijing), onde é discutido em detalhes o uso de pontos tensos ou dolorosos, também conhecido como “pontos de gatilho” ou “pontos motores”, para tratar a dor e disfunção, particularmente do sistema neuromusculoesquelético.
                                    </p>
                                    <p className="btmspace-30">
                                          As injeções de ponto-gatilho devem ser realizadas sob técnicas adequadas de antisepsia. Há pouca evidência que adicionar corticosteróides na injeção tenha qualquer efeito benéfico.
                                    </p>
                                    <p className="btmspace-30">
                                          Pode-se utilizar uma fina agulha de acupuntura, que é até 10 vezes mais fina que as agulhas de injeção comuns. O médico fisiatra palpa a musculatura dolorosa, localiza a banda miofascial tensa e o ponto gatilho, e faz a infiltração local. O paciente pode sentir uma contração local (twitch response), indicando que a infiltração foi realizada no ponto correto, inativando este ponto gatilho miofascial.
                                    </p>
                                    <p className="btmspace-30">
                                          Outra técnica possível é o uso de infiltração de pontos gatilho com solução anestésica. Para o conforto do paciente e para ajudar a decidir sobre a eficácia terapêutica, uma injeção de anestesia local, como lidocaína e/ou mepivacaína é possível.
                                          Os estudos de literatura médica mostram que a eficácia do agulhamento a “seco” (ou seja, sem o uso de anestésicos locais) é tão eficaz quanto a infiltração com anestésico. A vantagem do uso de anestésico é a diminuição da possível dor residual após o procedimento.
                                    </p>
                              </div>
                        </main>
                  </div>
            </>
      )
}

export default Acupuntura;