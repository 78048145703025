import HealthInsurance from "../../components/HealthInsurance";

function HealthInsurancePage() {
 return (
  <>
   <div className="wrapper row2 top-spacing40">
    <div className="center btmspace-40">
     <h6 className="heading underline font-x2">Convênios</h6>
    </div>
   </div>
   <HealthInsurance />
  </>
 )
}

export default HealthInsurancePage;