import geral from '../../assets/SpecialtiesImg/geral.jpg';

function DoresEmGeral() {

 return (
  <>
   <div className="wrapper row3" id="dores">
    <main className="hoc container clear">
     <article className="group">
      <div className="one_half first">
       <div className="imgover" href="">
        <img className="borderedbox inspace-10" src={geral} alt="Dra. Silvia" />
       </div>
      </div>
      <div className="one_half ">
       <h6 className="heading underline font-x2">Tratamento de dores em geral</h6>
       <p className="btmspace-30">
        A dor é uma maneira encontrada pelo corpo de sinalizar que alguma coisa não está
        funcionando bem. Ela é um problema muito amplo, pois pode existir em diversas
        partes do corpo, com diferentes motivos, níveis de intensidade, periodicidade e
        gravidade. Sendo assim, como podemos definir a complexidade da dor, que até hoje
        prejudica a vida de milhares de pessoas?
      </p>

      </div>

     </article>
     <div className="clear">

      <p className="btmspace-30">
       Você deve procurar um especialista em dor quando a dor não responde aos tratamentos usuais e habituais dentro de um período razoável de tempo.
      </p>

      <p className="btmspace-30">
       O especialista em dor, deve primeiramente fazer o diagnóstico correto da dor para poder ter sucesso em seu tratamento. Não adianta tratar o sintoma sem detectar a causa. O médico fisiatra tem a capacidade de diagnosticar corretamente.
      </p>

      <p className="btmspace-30">
       Dor crônica é a dor que persiste por semanas, meses ou anos, apesar das terapias e intervenções. Algumas pessoas podem sofrer durante décadas de dor crônica, mesmo na ausência de qualquer lesão específica ou evidência de doença.
      </p>

      <p className="btmspace-30">
       As vezes a dor crônica é parte de um processo de doença generalizada, e a causa específica pode ser difícil de identificar. Uma vez identificado esse fator específico, responsável pela dor, o tratamento pode ser mais eficaz.
      </p>

      <p className="btmspace-30">
       Você deve procurar um especialista em gerenciamento de dor quando a dor não responde aos tratamentos usuais e habituais dentro de um período razoável de tempo.
      </p>

      <p className="btmspace-30">
       Muitas vezes, as pessoas veem a gestão da dor como um último recurso para a dor.
      </p>

      <p className="btmspace-30">
       Se a dor persistir, contate imediatamente o seu médico especialista.
      </p>

      <h1><strong>Aplicação de toxina botulínica para espasticidade, distonia e dor</strong></h1>

      <p className="btmspace-30">
       Todos conhecem o efeito da toxina botulínica, quando usada com finalidade estética para atenuar rugas do rosto. A musculatura relaxa e a expressão fica menos contraída.
      </p>
      <p className="btmspace-30">
       O uso terapêutico da toxina botulínica teve início na área da oftalmologia. No começo da década de 1980, Dr. Scott, um oftalmologista, publicou o primeiro trabalho sobre o uso dessa toxina para relaxamento de músculos oculares com resultado eficaz no tratamento de estrabismo. Outros estudos se seguiram e, em 1989, foi publicado um trabalho mostrando que ela podia ser usada no tratamento dos distúrbios de movimento, especificamente nos casos de espasticidade (sequela de lesões do sistema nervoso central que provoca descontrole do tônus muscular tendendo à rigidez e à dificuldade de movimentos).
      </p>
      <p className="btmspace-30">
       Caracteristicamente, esse problema ocorre após traumatismo craniano, lesões medulares ou congênitas, como no caso da paralisia cerebral, uma encefalopatia provocada pela falta de oxigenação na fase perinatal ou intraútero. Como consequência da lesão, surge distúrbio do tônus muscular. O músculo se torna muito mais excitável, extremamente contraturado e contraído, o que provoca alteração dos movimentos e da postura.
      </p>
      <p className="btmspace-30">
       Dentro das especialidades médicas, o profissional que cuida da reabilitação do portador de deficiência é o fisiatra, mas existem profissionais de áreas afins, como a neurologia e a ortopedia, que também utilizam a toxina botulínica com finalidade terapêutica.
      </p>

      <h1><strong>Indicações</strong></h1>

      <p><strong> Espasticidade </strong></p>
      <p className="btmspace-30">
       Ela é utilizada para tratar as sequelas de lesões do sistema nervoso central. Atualmente, as mais comuns são as provocadas por lesões encefálicas adquiridas por causa de traumatismos de crânio e pelas provocadas por derrames cerebrais e paralisia cerebral.
      </p>
      <p className="btmspace-30">
       Lesões medulares por fratura, acidentes – motociclísticos, automobolísticos ou por arma de fogo – e doenças degenerativas, como a esclerose múltipla, podem provocar rigidez muscular e o tratamento feito com toxina botulínica traz benefícios para o paciente. Nesses casos, é preciso avaliar o padrão de rigidez que está interferindo com a qualidade de movimento ou com a postura e atuar sobre esses músculos.
      </p>
      <p><strong> Distonias e Tiques Nervosos </strong></p>

      <p className="btmspace-30">
       Muitos pacientes têm tiques, isto é, espasmos musculares faciais basicamente na região dos olhos (piscamentos) ou da boca (repuxamentos). Do ponto de vista medicamentoso e psicoterapêutico, isso é muito difícil de ser tratado. Como a toxina botulínica enfraquece a musculatura, os tiques são atenuados.
     </p>
      <p><strong> Cefaleias </strong></p>
      <p className="btmspace-30">
       A toxina presta-se para o tratamento das cefaleias tensionais, relacionadas com o estado de tensão e sobrecarga, e das cervicogênicas, resultantes de contratura na região cervical. De tratamento difícil, a profilaxia que se fazia antes deixava o paciente bem por uns tempos, mas depois surgiam crises intensas. O uso da toxina botulínica, nesses casos, traz melhores resultados do que o tratamento medicamentoso e prolonga o espaço entre as crises.
      </p>

      <p><strong> Duração do efeito </strong></p>
      <p className="btmspace-30">
       Vários fatores podem interagir para que o efeito dure mais ou menos tempo. Como sou da área de reabilitação, entendo que o paciente com sequela e portador de deficiência tem de fazer tratamento reabilitacional do momento em que a sequela apareceu até o final da vida. Hoje, não mais se discute que o paciente espástico, rígido, tem de fazer um trabalho de alongamento muscular e de reeducação do movimento para não gerar alterações nas estruturas articulares nem musculares.
      </p>
      <p className="btmspace-30">
       Em geral, o portador de espaticidade ou déficit motor, para andar ou movimentar o membro superior, ou mesmo para corrigir o posicionamento, precisa de um programa de atividades físicas e de acompanhamento terapêutico simultaneamente.
       Em média, varia de 4 a 6 meses.
     </p>

     </div>
    </main>
   </div>
  </>
 )
}

export default DoresEmGeral;