import Acupuntura from "./acupuntura";
import Fisiatria from "./fisiatria";
import DoresEmGeral from "./doresEmGeral";
import Canabis from "./cannabis ";

function Specialties() {

      return (
            <>
                  <Fisiatria />
                  <DoresEmGeral />
                  <Acupuntura />
                  <Canabis />
            </>
      )
}

export default Specialties;